/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import '../node_modules/reset-css/reset.css';

body, html {
  font-family: sans-serif;
  font-size: 16px;
  background-color: #f5f5f5;
}

/* ファイル入力を無効化 */
#file-input {
  display: none;
}

/* lazyload */
img {
  // transition: opacity 1s;
  // opacity: 0;
  backface-visibility: hidden;
}

img.ng-lazyloaded {
  opacity: 1;
}

/* object-fitをEDGE,IE11で有効化 */
.object-fit-image {
  object-fit: contain;
  font-family: 'object-fit: contain';
}

/* Safariで検索バーの検索欄が真っ白になってしまうのを防ぐ対応 */
input[type="search"] {
  -webkit-appearance: none;
}

/* IE対応 */
/* Angular Materialのテーブル対応 */
.mat-header-row {
  .mat-header-cell {
    min-height: auto;
  }
}

.mat-row {
  .mat-cell {
    min-height: auto;
  }
}

/* Angular Materialのsidenav対応 */
.mat-drawer-content {
  overflow: visible !important;
  height: 100vh;
}

.tag-highlighted {
  color: blue; /* Ensures the text color is blue */
  text-decoration: underline; 
}