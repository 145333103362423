@use '@angular/material' as mat;
@include mat.core();
// @see material2/src/lib/core/theming/_palette.scss

// Postfor
$postfor-primary: #00ACC1;
$postfor-accent: #009688;
$postfor-red: #f75b4b;
$postfor-yellow: #ffbc5a;
$postfor-green: #23bba0;
$postfor-background: #f7f7f5;
$postfor-deepbackground: #ededed;

$mat-postfor: (
    50 : #e0f5f8,
    100 : #b3e6ec,
    200 : #80d6e0,
    300 : #4dc5d4,
    400 : #26b8ca,
    500 : #00acc1,
    600 : #00a5bb,
    700 : #009bb3,
    800 : #0092ab,
    900 : #00829e,
    A100 : #c9f4ff,
    A200 : #96e9ff,
    A400 : #63dfff,
    A700 : #49daff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$postfor-theme-primary: mat.m2-define-palette($mat-postfor);
$postfor-theme-accent: mat.m2-define-palette(mat.$m2-teal-palette);
$postfor-theme-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme
$postfor-theme: mat.m2-define-light-theme((
  color: (
    primary: $postfor-theme-primary,
    accent: $postfor-theme-accent,
    warn: $postfor-theme-warn,
  ),
  background: (
    background: $postfor-background,
    hover: $postfor-deepbackground,
  ),
));


// Include the theme styles
@include mat.all-component-themes($postfor-theme);

.alternate-theme {
  // Define alternate primary and accent palettes
  $alternate-primary: mat.m2-define-palette(mat.$m2-light-blue-palette);
  $alternate-accent: mat.m2-define-palette(mat.$m2-yellow-palette, 400);

  // Create the alternate theme
  $alternate-theme: mat.m2-define-light-theme((
    color: (
      primary: $alternate-primary,
      accent: $alternate-accent,
    )
  ));

  // Include the alternate theme styles
  @include mat.all-component-colors($alternate-theme);
}
